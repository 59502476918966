import React from 'react';

const IconLoader = () => (
  <svg id='logo' xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1600 1310" width="1280" height="1280">
    <path transform="translate(398,212)" d="m0 0 7 6 48 48 5 4 4 4v2l3 1v2l3 1 4 4v2l3 1v2l3 1v2l3 1 4 4v2l3 1v2l3 1v2l4 2 103 103-2 4-196 196 2 4 196 196-2 4-151 151h-2l-2 4-6 6h-2l-1 3h-2l-1 3h-2l-2 4h-2l-1 3h-2l-1 3-8 7-12 12-7-6-8-8-8-7-178-178-9-11-10-11-12-16-11-17-12-22-8-18-10-30-6-27-3-22-1-14v-26l2-22 3-19 6-24 8-24 9-20 7-14 10-17 10-14 11-14 15-16 9-10 20-20h2l1-3h2l1-3 5-4 5-6 5-4 4-5 6-5 4-5 6-5 4-5 8-7z" fill="#4571D4" />
    <path transform="translate(1179,193)" d="m0 0 4 2 15 14 32 32 6 5 4 5 6 5v2l3 1v2l3 1v2l4 2v2l3 1v2l4 2 113 113 7 8 12 13 13 16 12 18 10 18 8 16 8 20 7 22 5 22 4 29v47l-4 28-6 25-9 27-11 24-10 19-8 12-14 19-12 14-14 15-103 103h-2l-1 3h-2l-1 3-5 4-4 5-6 5-4 5-8 7-57 57-7-6-37-37-8-7-148-148 1-3 8-7 22-22 2-3h2l2-4 3-3h2l1-3h2l1-3h2l2-4 3-3h2l1-3h2l1-3h2l1-3h2l2-4h2l1-3h2l1-3h2l1-3h2l2-4h2l1-3h2l2-4 110-110h2l1-4-4-2-5-5v-2h-2l-4-5-184-184 6-7z" fill="#4571D4" />
    <path transform="translate(398,212)" d="m0 0 7 6 48 48 5 4 4 4v2l3 1v2l3 1 4 4v2l3 1v2l3 1v2l3 1 4 4v2l3 1v2l3 1v2l4 2 103 103-2 4-106 106-5 3-7 7h-2l-1 3-3-1 3-10 3-6v-11l2-4-2-5-8-7-8-3-8-2h-6l-7-2v-2h-17l-17 1-9 1v2l-9 4-8 2-14 1-27 1-7-2-24-2v-1l10-2-24 1-7-4-9-5-2 4-6-3-11-1-21 3-10 1-13-1-10-1h-8l-5-3-1-3h-11l-3 2-12 3-5 1-3-3 2-3v-10l2-5 2-1 2-7 12-16 9-10 7-8 14-15 18-18h2l1-3h2l1-3 5-4 5-6 5-4 4-5 6-5 4-5 6-5 4-5 8-7z" fill="#343DC1" />
    <path id="B" transform="translate(661,521)" d="m0 0h12l3 1h51l20 1v41l-1 59-1 37 25-1h39l31 1v-135l1-2 19-1h70l1 3v187l-3 13-7 11-7 7-12 6-4 1h-48l-3-2-4 2h-6l1 9-1 3v217l-4-1-8-7-10-10-7-8-8-8-7-8-12-12-7-8-8-8-7-8-5-5-5-6-2-6v-62l1-11-2-5 1-49 2-8-7 1-37-1h-12l-12-4-10-7-7-7-6-12-1-4v-12l-1-2v-25l1-68v-81l2-5zm7 3-3 1v2l5-2z" fill="#FCFCFC" />
    <path transform="translate(398,612)" d="m0 0 4 2 196 196-2 4-151 151h-2l-2 4-6 6h-2l-1 3h-2l-1 3h-2l-2 4h-2l-1 3h-2l-1 3-8 7-12 12-7-6-6-6-1-3-2-1v-4h-2l-5-5-2-4-4-4-10-15-5-7-2-7h-2l-3-8-5-10v-2h-2l-10-27-6-20-5-25-3-26-1-14v-13l1-20 4-27 7-28 8-23 11-24 8-15 12-18 9-12 12-14z" fill="#801178" />
    <path transform="translate(1179,193)" d="m0 0 4 2 9 8 3 5 8 8 8 11 4 8 2 1 5 10 2 1v5l3 1 3 10h2l5 12 4 8 4 10 3 10 3 9 4 15 4 20 2 12 1 16v38l-3 26-6 27-11 33-11 24-14 24-14 19-11 13-11 11-4-1-6-6v-2h-2l-4-5-184-184 6-7z" fill="#811178" />
    <path transform="translate(1192,204)" d="m0 0 7 6 31 31 6 5 4 5 6 5v2l3 1v2l3 1v2l4 2v2l3 1v2l4 2 113 113 7 8 12 13 13 16 12 18 10 18 8 16 8 20 7 22 5 22 3 20v4l-5-1-9-2-4 5-20 2h-13l-5 1-27-1-12-2-12-4-10-4h-6l-8 4-14 2-13 5-6 7-13 8-10 3h-23l-4 4h-6l-4-4-5 3-6 1-1-4-6 3-10 1-5-4-3-1 4-8h-6l-1 2-3 1-4 5-5 4-2 4-3 3-6 2v3l-5 3-104 104v-3l111-111h2l2-5 14-14 13-17 10-14 15-27 11-26 8-25 6-27 3-26v-38l-1-16-4-23-5-22-3-10-3-6-3-9-5-13-4-7-1-6h-2l-4-10-2-1v-5l-4-2-3-6v-3l-3-1-4-8-10-13-8-8zm-129 504 2 1-3 2zm-3 3 2 1-4 3zm-4 4 2 1-3 2zm-3 3 2 1-3 2zm-3 3 2 1-3 2zm-3 3 2 1-4 3zm-4 4 2 1-3 2z" fill="#4E8CDF" />
    <path transform="translate(1406,750)" d="m0 0 2 1-1 4-2 3-2 7-9 11-18 20-105 105h-2l-1 3h-2l-1 3-5 4-4 5-6 5-4 5-8 7-57 57-7-6-37-37-8-7-148-148 1-3 4-3 4 1 1-2 7 1 6 5v3h8l8 5h12l1-3h26l12-3 18-1 13 3h15l19-3 2-2h3l1-3 16-5h12l-1 3 8 4 8 2 29 3 7-2h29l1-1 15 1 1 2-3 1-1 2 11 2h12l11-4 21-4 5-2 15-4 13-2 11-4 5-4 7-1v-2l6-4 8-5 6-3 8-6h2z" fill="#343DC1" />
    <path transform="translate(1438,686)" d="m0 0 3 4-1 6-3 9-3 10-12 23-8 12-9 12-1-4 3-4-1-3-5 5-8 6-12 6-5 4h-2v2l-8 3-6 4-12 4-14 2-13 3-3 2-21 4-11 4h-12l-11-3 1-3 2-1-15-1-2 1h-28l-10 2-31-4-10-4-2-3 1-1h-11l-11 3-5 2-1 2-5 3-19 3h-15l-13-3-18 1-12 3h-26l-1 2-8 1-5-1-7-4h-6l-2-1v-3l-3-1v-2l-8-2-5 1v-3l24-24 2-3h2l2-4 3-3h2l1-3h2l1-3h2l2-4 3-3h2l1-3h2l1-3 9-5 5-4 9-7 5-1v-2h4l1-2h3l3 5 7 2h9l8 2 15-1h11l23-1 16 2 11 1 22-3 10-3 3 1h-2v2l18-6h16l7-3 16-1 1 3h16l3-3 5 1h13l10-1 3 2 7 1 4 2 1 3 11 1 10 2-1-4 14-2 10-5 25-2 16-5 11-6 6-6z" fill="#3E58CB" />
    <path transform="translate(240,370)" d="m0 0v3l-1 4-4 4-3 5-3 4v4l8-1 12-4h14l7 1 9 2 1 4 13 2 5 1h21l17-1 6-1 1 3h6l3 8 5 3 6 1h16l12 2 9 3 7 2 4 2h38l31-5 8-1 2 2h8l3 1 17-1 4-1 2 2 10 2v-2l7 1 1-1h7l3 6 5 2-2 4-4 6-2 12-3 6v2l4 1 16-16 2 1-78 78-5 3-7 7h-2l-1 3-3-1 3-10 3-6v-11l2-4-2-5-8-7-8-3-8-2h-6l-7-2v-2h-17l-17 1-9 1v2l-9 4-8 2-14 1-27 1-7-2-24-2v-1l10-2-24 1-7-4-9-5-2 4-6-3-11-1-21 3-10 1-13-1-10-1h-8l-5-3-1-3h-11l-3 2-12 3-5 1-3-3 2-3v-10l2-5 2-1 2-7 12-16 9-10 7-8 14-15 18-18h2l1-3h2l1-3 5-4z" fill="#3E58CB" />
    <path transform="translate(128,690)" d="m0 0 3 1 2 5v4l4 4 2 5 4 4 1 6h2l6 8v3h6l4-5 14-8 18-5h9l24 3 6 2 33 4 10 4 10 5 16 4h8v2l5-3 3 1 1-3 4-1 1 2-1 9-3 16-3 21-1 12v31l1 12v15l3 1 4 23 6 23 7 20 4 11v2h2l8 16v4h2l4 8 12 18 3 5 4 3 1 3 4 3v2h2v4l3 1-1 2-10-9-177-177-9-11-10-11-12-16-11-17-12-22-8-18-8-23z" fill="#55A3E7" />
    <path transform="translate(119,592)" d="m0 0h1l2 20 2 6 1 7 3 5v2l4 2 8-8 11-5 11-7 19-5h14l8 4 17 3 10 5 7 6 7 3 9 2h13l10-1 1-3 9-5 16-2 5 1h5l10 4 13 4 4 1 2 7 5 5 2 5 2 1v3l4 1 3 1 5-2-1-3 4-1 6-8 2-4 5-5 4-3-2 4-10 13-12 18-9 16-10 21-9 25-6 22-4 21-2 15-1 20v13l2 24v8h-2l-2-27v-31l3-27 3-19 2-6v-8l-4 1-1 4-1-2-5 3h-2v-2l-8 1-16-4-10-5-10-4-33-4-8-2-24-3h-8l-19 6-9 5-3 1-2 4-2 2-6-1-4-7-2-4h-2l-2-4-1-4-4-4-2-5-3-2-1-9-4-1-6-26-4-27-1-14v-26l2-3z" fill="#4E8CDF" />
    <path transform="translate(1192,204)" d="m0 0 7 6 31 31 6 5 4 5 6 5v2l3 1v2l3 1v2l4 2v2l3 1v2l4 2 113 113 7 8 12 13 13 16 12 18 10 18 8 16 8 20 2 8-7-6 2-1v-2l-3-1 2-1-6-7-2-5-4-6-2-2h-8l-1 1 1 5-3 1-1-4-11 3-7 1-16-3-9-3-14-1h-32l-10 1h-8l-12 2-15-3-5 2-7 1-5-9v-2l-2-1 2-6-4 5-2 1 1-7h-2l1-14v-11l2-18v-38l-1-16-4-23-5-22-3-10-3-6-3-9-5-13-4-7-1-6h-2l-4-10-2-1v-5l-4-2-3-6v-3l-3-1-4-8-10-13-8-8z" fill="#54A2E6" />
    <path transform="translate(1179,193)" d="m0 0 4 2 9 8 3 5 8 8 8 11 4 8 2 1 5 10 2 1v5l3 1 3 10h2l5 12 4 8 4 10 3 10 3 9 4 15 4 20-1 3-1-3h-2l-7-18-5-12-2 1-3 8-4-1-16-5-10-6-5-4-11-2-31-4-18-5-19-5-24-4-3-1h-7l-5 1 1-4z" fill="#591E7B" />
    <path transform="translate(168,773)" d="m0 0 4 2 7 6 4 5v2l4 2 10 9 15 15 10 6 5 4 5-1 1-5 2-4 5-3h2v-2l-6-1 4-3h4v4l4-4h9l4 1v4h7 3 2v2l9 3 9 1 5 2h5l1 3 2-1 5-12h2l2-2 1 15h2l1 12v15l3 1 4 23 6 23 7 20 4 11v2h2l8 16v4h2l4 8 12 18 3 5 4 3 1 3 4 3v2h2v4l3 1-1 2-10-9-177-177-9-11-10-11-11-15z" fill="#58B4ED" />
    <path transform="translate(516,889)" d="m0 0 4 1-75 75h-2l-2 4-6 6h-2l-1 3h-2l-1 3h-2l-2 4h-2l-1 3h-2l-1 3-8 7-12 12-7-6-6-6-1-3-2-1v-4h-2l-5-5-2-4-4-4-10-15-5-7-2-7h-2l-3-8-2-3 1-3 5 9 4 7 2-1-2-12-3-9v-6l7-3 5-3h8l12-4 6-1h23l18 1h13l23-4 8-2 15-5v1l7-1 4-3h2v-2z" fill="#591F7C" />
    <path transform="translate(1192,204)" d="m0 0 7 6 31 31 6 5 4 5 6 5v2l3 1v2l3 1v2l4 2v2l3 1v2l4 2 113 113 7 8 12 13 2 4h-5v-2h-3v-2h-3v-2l-4-2-4-3v-2l-5-2-9-6-2 1-7-1-2 4-7-1-4 1h-12l-16-4-3-3-18-6-14-9-6-9-4-7-3-5v-2l-3-1-3-4-5-21-6-14-3-9-4-11-5-13-4-7-1-6h-2l-4-10-2-1v-5l-4-2-3-6v-3l-3-1-4-8-10-13-8-8z" fill="#58B4ED" />
    <path transform="translate(235,841)" d="m0 0 4 2 13 11 5 4v2l4 2 8 5 11 9 8 4 8 1 5 1 2 1-2-6 8-2 2 3-1-7 2-5 2-7 4-2 4 13 2 2 6 23 7 20 4 11v2h2l8 16v4h2l4 8 12 18 3 5 4 3 1 3 4 3v2h2v4l3 1-1 2-10-9-136-136v-4h-2z" fill="#56BCF2" />
    <path transform="translate(1192,204)" d="m0 0 7 6 31 31 6 5 4 5 6 5v2l3 1v2l3 1v2l4 2v2l3 1v2l4 2 39 39 1 4 7 7v2l3 1 3 3v2h2l4 5 2 1 3 5 4 4v2h2v2l-6-1-15-10-5-3-6-5-9-3-10-2-1-4h-2l-3-5-13-5-2 1-7-6v-3l-2-1-9-20-3-6v-2l-3-1v-3l-4-2-1-6h-2l-4-10-2-1v-5l-4-2-3-6v-3l-3-1-4-8-10-13-8-8z" fill="#56BEF3" />
    <path transform="translate(628,257)" d="m0 0h6l3 5 20 60 4-10 16-47 3-6 2-2h5l3 5 12 35 8 25 4-9 18-54 3-2h5l1 3-15 43-9 27-3 5-7-1-3-5-16-47-4-11-11 30-8 24-4 9-5 1-4-2-8-22-12-36-5-17z" fill="#FCFCFC" />
    <path transform="translate(876,257)" d="m0 0h30l15 2 8 5 4 6 1 3v9l-3 7-6 5 5 4 5 5 3 6v11l-5 8-5 4-7 2-29 1h-17l-1-1-1-75zm6 7v28l1 1h12l20-1 6-3 4-5 1-9-3-6-5-4-5-1zm0 35v29h36l8-4 3-4 1-3v-7l-3-6-6-4-4-1z" fill="#FCFCFC" />
    <path transform="translate(778,257)" d="m0 0h52l1 1v7h-45v28l23-1h16l1 1v5l-8 1h-32l1 28h45l1 6-1 1-18 1h-35l-1-1z" fill="#FBFBFB" />
    <path transform="translate(1179,193)" d="m0 0 4 2 9 8 3 5 8 8 6 8-1 2-16-17-5-5v-2l-4-2-3-2-4 1-91 91 1-4z" fill="#613492" />
    <path transform="translate(1174,597)" d="m0 0 1 4-5 3-104 104v-3zm-111 111 2 1-3 2zm-3 3 2 1-4 3zm-4 4 2 1-3 2zm-3 3 2 1-3 2zm-3 3 2 1-3 2zm-3 3 2 1-4 3zm-4 4 2 1-3 2z" fill="#4D97E3" />
    <path transform="translate(986,792)" d="m0 0h2v2l3-1 3 3v2l4 1 2 4 5 7 3 3v2l3 1-1 2-4-1-21-21 2-1z" fill="#405FCD" />
    <path transform="translate(1333,345)" d="m0 0 4 2 39 39 2 4-5-2-2-4-3-1v-2l-3-1v-2l-3-1-7-8-6-6v-2l-4-2-6-6v-2l-3-1v-2l-3-1z" fill="#55A5E7" />
    <path transform="translate(1037,734)" d="m0 0 2 1-9 10-39 39-1 3-3-1 6-7 19-19 2-3h2l2-4 3-3h2l1-3h2l1-3h2l2-4 3-3h2z" fill="#4473D5" />
    <path transform="translate(1261,365)" d="m0 0h2l2 32-2 25-2 1z" fill="#4570D4" />
    <path transform="translate(1179,193)" d="m0 0 4 2 9 8 3 5 8 8 6 8-1 2-16-17-5-5v-2l-4-2-6-4z" fill="#3748C5" />
    <path transform="translate(1301,313)" d="m0 0 4 2 29 29-3 1-5-6-5-3v-2l-3-1v-2h-2v-2l-3-1-7-8-5-5z" fill="#57B2ED" />
    <path transform="translate(383,992)" d="m0 0 4 2v2l3 1 5 6 7 1v3l-4 3-12-12-1-3-2-1z" fill="#394DC7" />
    <path transform="translate(324,738)" d="m0 0h1l-1 9-4 21-2 1 1-12 3-16z" fill="#4C85DC" />
    <path transform="translate(373,980)" d="m0 0 3 2 1 3 4 3v2h2v4l3 1-1 2-10-9-5-6 4 1z" fill="#4A85DC" />
    <path transform="translate(1174,597)" d="m0 0 1 4-5 3-19 19-2-1z" fill="#4F9CE5" />
    <path transform="translate(1252,316)" d="m0 0 3 3 4 15-1 6-2-4-4-16z" fill="#4A80DA" />
    <path transform="translate(1317,329)" d="m0 0 4 2 13 13-3 1-5-6-5-3v-2l-3-1z" fill="#56AFEC" />
    <path transform="translate(381,628)" d="m0 0v3l-12 15-2-1 4-5 2-4 5-5z" fill="#4D89DE" />
    <path transform="translate(1235,271)" d="m0 0h2l1 3 3 3 1 10-7-14z" fill="#4C88DD" />
    <path transform="translate(1258,439)" d="m0 0 2 3-2 11-2 1 1-9z" fill="#4777D7" />
  </svg>
);

export default IconLoader;
