import React from 'react';

const IconLogo = () => (
  <svg id='logo' xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1586 1000" width="1280" height="1280">
<path transform="translate(345,377)" d="m0 0 7 6 55 55-6 7-54 54 2 4 58 58-6 7-55 55-7-6-58-58-10-14-7-14-4-14-1-6v-22l3-14 5-13 7-12 11-13z" fill="#4368D1"/>
<path transform="translate(1240,378)" d="m0 0 4 2 10 9 49 49 9 11 8 14 5 13 3 17v13l-3 17-6 16-6 10-8 10-63 63-4-2-58-58 6-7 37-37 7-8 9-9-2-4-14-14-7-8-36-36 6-7z" fill="#4368D1"/>
<path transform="translate(1198,543)" d="m0 0 31 1 82 1 2 4-8 10-63 63-4-2-58-58 6-7z" fill="#3B4EC9"/>
<path transform="translate(345,377)" d="m0 0 7 6 55 55-6 7-11 11-68-1-48-1v-4l11-13z" fill="#3B4EC9"/>
<path transform="translate(345,500)" d="m0 0 7 6 55 55-6 7-22 22-41 1-13-2-3-10-1-6v-25l5-19 9-17z" fill="#8D107B"/>
<path transform="translate(1208,410)" d="m0 0 9 1 32-1 13 2 3 11 1 7v18l-3 15-5 13-6 11-8 10-2 2-4-1-15-15-7-8-36-36 6-7z" fill="#8C107B"/>
<path transform="translate(262,523)" d="m0 0 64 2 1 4-5 19v25l3 16 3-1 11 2 35-1 4 2-32 32-7-6-58-58-10-14-7-14z" fill="#75A7E2"/>
<path transform="translate(1240,378)" d="m0 0 4 2 10 9 49 49 9 11 8 14 3 7-1 4-62-1 1-7 3-11 1-7v-18l-3-15v-3l-3 1-11-2-36 1-3-1 2-4z" fill="#75A7E2"/>
<path transform="translate(1033,439)" d="m0 0h15l9 11 14 17 9 11 3 4 1-43h16v73h-14l-11-13-8-10-9-11-7-9-1 43h-17z" fill="#FEFEFE"/>
<path transform="translate(967,438)" d="m0 0h17l12 4 9 7 6 8 4 11v16l-5 12-8 9-8 5-11 3h-16l-10-3-9-6-8-9-4-10-1-14 4-13 6-8 8-7 10-4zm4 14-10 4-6 7-2 5v15l4 8 6 5 8 3h9l8-3 5-5 4-6 1-3v-12l-3-8-4-5-7-4-4-1z" fill="#FEFEFE"/>
<path transform="translate(720,439)" d="m0 0h17l1 48 4 8 7 3h7l9-3 3-9 1-47h17v47l-4 13-7 8-10 5-5 1h-15l-10-3-7-5-5-7-3-10z" fill="#FEFEFE"/>
<path transform="translate(560,439)" d="m0 0h17l1 44 2 8 4 5 6 2h7l7-3 4-6 1-4 1-46h17v43l-2 11-5 10-9 7-10 3h-15l-10-3-8-6-6-10-2-11z" fill="#FEFEFE"/>
<path transform="translate(666,438)" d="m0 0h18l13 4 3 2-3 10-3 3-10-4-4-1h-8l-9 3-1 7 6 4 22 6 8 5 4 5 2 9-2 8-4 6-7 5-10 3h-17l-15-4-6-4 3-9 3-4 11 5 9 2h7l9-2 1-6-5-5-22-6-10-6-4-6-1-4v-7l3-8 8-7 6-3z" fill="#FEFEFE"/>
<path transform="translate(808,439)" d="m0 0h55v14h-38v19h34v13h-34v27h-17z" fill="#FEFEFE"/>
<path transform="translate(876,439)" d="m0 0h42v54l-4 11-7 6-8 3h-14l-11-4-6-7 8-10 4 2 6 4h9l5-5 1-40h-25z" fill="#FEFEFE"/>
<path transform="translate(476,439)" d="m0 0h18l6 9 13 22 4-5 15-25 1-1h16l-2 5-16 27-9 15-1 26h-17l-1-28-13-21-14-23z" fill="#FEFEFE"/>
<path transform="translate(1240,378)" d="m0 0 4 2 3 2 3 5 8 13 4 10v2l-3 1-11-2-36 1-3-1 2-4z" fill="#3B4EC9"/>
<path transform="translate(327,588)" d="m0 0 12 2 35-1 4 2-32 32-7-6-6-9-8-16v-3z" fill="#3B4FC9"/>
<path transform="translate(538,539)" d="m0 0h3l9 25 8-24 4-1 8 22v3h2l2-9 6-16h3l-3 11-7 20h-4l-8-23-1-1-8 23-4 1-4-10-6-18z" fill="#FEFEFE"/>
<path transform="translate(636,539)" d="m0 0h16l5 2 3 4-1 7-2 2 4 4 1 5-3 5-4 2h-19zm3 3v11h14l4-4-1-5-3-2zm0 14v11h16l3-3v-5l-3-3z" fill="#FEFEFE"/>
<path transform="translate(698,539)" d="m0 0h16l7 3 5 6 1 3v8l-4 7-8 4h-17zm3 3v25h15l6-5 2-4-1-9-4-5-5-2z" fill="#FEFEFE"/>
<path transform="translate(901,539)" d="m0 0h8l6 3 5 6 1 2v9l-3 6-5 4-3 1h-10l-6-3-4-5-1-3v-9l4-6 5-4zm1 3-6 3-4 6v7l3 5 5 4h10l7-6 1-9-3-6-6-4z" fill="#FEFEFE"/>
<path transform="translate(1015,539)" d="m0 0h14l7 3 3 4v8l-5 5 2 5 3 4v2l-4-1-6-8h-10l-1 9h-3zm3 3v15h14l4-4v-6l-5-5z" fill="#FEFEFE"/>
<path transform="translate(977,539)" d="m0 0h21v3h-18v11h16v3h-16v11h19v3h-22z" fill="#FEFEFE"/>
<path transform="translate(743,539)" d="m0 0h21v3h-18v11h16v3h-16v11h19v3h-22z" fill="#FEFEFE"/>
<path transform="translate(598,539)" d="m0 0h21v3h-18v11h16v3h-16v11h18l1 3h-22z" fill="#FEFEFE"/>
<path transform="translate(820,539)" d="m0 0h20l1 3h-18v11h16l-1 3h-15v11h18v3h-21z" fill="#FEFEFE"/>
<path transform="translate(937,539)" d="m0 0h14l7 3 3 4v8l-5 5-2 1-13 1-1 9h-3zm3 3v15h13l3-1 2-3v-6l-5-5z" fill="#FEFEFE"/>
<path transform="translate(776,539)" d="m0 0 4 1 10 22v3h2l3-9 7-16 4-1-3 9-10 22h-4l-13-29z" fill="#FEFEFE"/>
<path transform="translate(858,539)" d="m0 0h3v28h17v3h-20z" fill="#FEFEFE"/>
</svg>
);

export default IconLogo;
